<template>
  <section id="services">
    <div class="py-6 py-md-12"></div>

    <v-container class="text-center">
      <h2 class="display-2 font-weight-bold secondary--text mb-3">
        Os nossos serviços
      </h2>

      <v-responsive class="mx-auto mb-8" width="56">
        <v-divider></v-divider>
      </v-responsive>
    </v-container>

    <v-row no-gutters>
      <v-col cols="12" md="4" v-for="service in services" :key="service.id">
        <service-card :service="service" />
      </v-col>
    </v-row>
  </section>
</template>

<script>
import ServiceCard from "@/components/ServiceCard.vue";
import { SERVICES } from "@/utils/constants.js";

export default {
  components: {
    ServiceCard
  },
  data() {
    return {
      selectedService: 0,
      services: SERVICES
    };
  }
};
</script>

<style scoped>
.slide-picker {
  position: absolute;
  bottom: 0;
  margin: 0 auto;
  width: 100%;
}
</style>
