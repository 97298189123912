<template>
  <v-navigation-drawer app v-model="drawerStatus" temporary>
    <v-list>
      <template v-for="btn in menu">
        <v-list-item
          v-if="!btn.submenu"
          :key="btn.name"
          link
          to="/"
          active-class="no-active"
          @click="onDrawerClick(btn.name)"
        >
          <v-list-item-content>
            <v-list-item-title class="text-uppercase">
              {{ $t(`menu.${btn.name}`) }}
            </v-list-item-title>
          </v-list-item-content>
        </v-list-item>

        <v-list-group v-else :key="btn.name">
          <template v-slot:activator>
            <v-list-item-content>
              <v-list-item-title class="text-uppercase">
                {{ $t(`menu.${btn.name}`) }}
              </v-list-item-title>
            </v-list-item-content>
          </template>

          <v-list-item
            v-for="btn in btn.submenu"
            :key="btn.name"
            link
            :to="btn.name"
            active-class="no-active"
          >
            <v-list-item-title class="pl-3">
              {{ $t(`services.${btn.name}.title`) }}
            </v-list-item-title>
          </v-list-item>
        </v-list-group>
      </template>
    </v-list>
  </v-navigation-drawer>
</template>

<script>
export default {
  name: "app-drawer",

  props: {
    menu: {
      required: true
    },
    drawer: {
      required: true
    }
  },

  data: () => ({
    drawerStatus: false
  }),

  watch: {
    drawer(val) {
      this.drawerStatus = val;
    },
    drawerStatus(val) {
      this.$emit("update:drawer", val);
    }
  },

  methods: {
    onDrawerClick(key) {
      this.$vuetify.goTo(`#${key}`);
      this.$emit("update:drawer", false);
    }
  }
};
</script>

<style lang="sass" scoped>
.v-list-item--active.no-active
  &:not(:focus):not(:hover)::before
    opacity: 0 !important

  &:focus::before,
  &:hover::before
    opacity: 0.08 !important
</style>
