<template>
  <base-section id="team" color="grey lighten-3" :title="$t('team.title')">
    <v-row justify="space-around">
      <v-col v-for="(person, p) in people" :key="p">
        <v-card flat color="transparent">
          <div>
            <v-avatar class="elevation-3" size="200">
              <v-img :src="require(`../assets/${person.img}`)"></v-img>
            </v-avatar>

            <v-card-title class="d-block text-center">
              {{ person.name }}
            </v-card-title>
            <v-card-subtitle class="d-block body-1 text-center">
              {{ person.degree }}
            </v-card-subtitle>
            <v-card-text>
              <div>
                <v-icon color="primary" small class="mr-2">mdi-phone</v-icon>
                <a :href="`tel:+351${person.phone}`">{{ person.phone }}</a>
              </div>
              <span class="caption text--secondary">
                Preço de uma chamada para a rede móvel.
              </span>
            </v-card-text>
          </div>
        </v-card>
      </v-col>
    </v-row>
  </base-section>
</template>

<script>
import BaseSection from "@/components/BaseSection.vue";

export default {
  name: "Team",

  components: {
    BaseSection
  },

  data: () => ({
    people: [
      {
        name: "Anabela Moreira",
        degree: "Licenciada em Contabilidade",
        img: "anabela.jpg",
        phone: "917 668 115"
      },
      {
        name: "Cátia Moreira",
        degree: "Licenciada em Gestão de Empresas",
        img: "catia.jpg",
        phone: "917 997 815"
      }
    ]
  })
};
</script>
