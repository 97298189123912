<template>
  <div>
    <section>
      <v-parallax
        :height="!$vuetify.breakpoint.mdAndDown ? 400 : 200"
        :src="selectedService.imgUrl"
        :srcset="selectedService.srcset.toString()"
      >
        <v-overlay absolute>
          <v-container class="justify-start">
            <div class="text-h4 text-center text-md-h2">
              {{ $t(`services.${service}.title`) }}
            </div>
          </v-container>
        </v-overlay>
      </v-parallax>

      <div class="py-6 py-md-12"></div>

      <v-container>
        <v-responsive class="mx-auto body-1 font-weight-light" max-width="1024">
          <div class="d-flex flex-column align-center">
            <v-avatar class="pa-8 " color="secondary" size="200">
              <div class="fill-height">
                <inline-svg
                  width="100%"
                  :src="require(`@/assets/${service}.svg`)"
                  @loaded="svgLoaded"
                ></inline-svg>
              </div>
            </v-avatar>

            <div
              class="mt-16 my-auto"
              v-html="$t(`services.${service}.text`)"
            ></div>

            <div v-if="service === 'pagaqui'">
              <v-img
                class="my-12 mx-auto"
                width="200"
                :src="require('@/assets/pagaqui_logo.png')"
              />
              <v-container>
                <v-row justify="space-around">
                  <v-col cols="12" md="4" v-for="(item, i) in items" :key="i">
                    <v-card flat color="transparent" class="text-center">
                      <v-card-title class="d-block">
                        {{ item.title }}
                      </v-card-title>
                      <v-card-subtitle
                        class="d-block body-1"
                        v-html="!item.subtitle ? '&nbsp;' : item.subtitle"
                      />
                    </v-card>
                  </v-col>
                </v-row>
                <span class="d-block text-center pt-8">
                  {{ $t(`services.${service}.extra`) }}
                </span>
              </v-container>
            </div>
          </div>
        </v-responsive>
      </v-container>

      <div class="py-6 py-md-12"></div>
    </section>
  </div>
</template>

<script>
import { SERVICES } from "@/utils/constants.js";
import Vivus from "vivus";
import InlineSvg from "vue-inline-svg";

export default {
  components: {
    InlineSvg
  },

  props: {
    service: String
  },

  data() {
    return {
      ctx: null,
      items: [
        {
          title: this.$t(`services.${this.service}.carregamentos.title`),
          subtitle: this.$t(`services.${this.service}.carregamentos.subtitle`),
          commission: this.$t(
            `services.${this.service}.carregamentos.commission`
          ),
          notes: [
            this.$t(`services.${this.service}.carregamentos.notes1`),
            this.$t(`services.${this.service}.carregamentos.notes2`)
          ]
        },
        {
          title: this.$t(`services.${this.service}.cartoesPresente.title`),
          commission: this.$t(
            `services.${this.service}.cartoesPresente.commission`
          ),
          notes: [this.$t(`services.${this.service}.cartoesPresente.notes1`)]
        },
        {
          title: this.$t(`services.${this.service}.cartoesTelefonicos.title`),
          commission: this.$t(
            `services.${this.service}.cartoesTelefonicos.commission`
          ),
          notes: [this.$t(`services.${this.service}.cartoesTelefonicos.notes1`)]
        },
        {
          title: this.$t(`services.${this.service}.titulosTransporte.title`),
          commission: this.$t(
            `services.${this.service}.titulosTransporte.commission`
          ),
          notes: [this.$t(`services.${this.service}.titulosTransporte.notes1`)]
        },
        {
          title: this.$t(`services.${this.service}.pagamentoFaturas.title`),
          commission: this.$t(
            `services.${this.service}.pagamentoFaturas.commission`
          )
        },
        {
          title: this.$t(`services.${this.service}.seguros.title`),
          commission: this.$t(`services.${this.service}.seguros.commission`)
        },
        {
          title: this.$t(`services.${this.service}.bilhetesEspetaculos.title`),
          commission: this.$t(
            `services.${this.service}.bilhetesEspetaculos.commission`
          ),
          notes: [
            this.$t(`services.${this.service}.bilhetesEspetaculos.notes1`)
          ]
        }
      ]
    };
  },

  methods: {
    svgLoaded(svg) {
      this.ctx = new Vivus(svg, { duration: 100 });
    }
  },

  computed: {
    selectedService() {
      return SERVICES.find(s => s.id === this.service);
    }
  }
};
</script>
