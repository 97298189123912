<template>
  <base-section id="about" :title="$t('about.title')">
    <p v-html="$t('about.text1')"></p>
    <p>{{ $t("about.text2") }}</p>
    <p>{{ $t("about.text3") }}</p>

    <div class="pb-12"></div>

    <v-row>
      <v-col v-for="item in list" :key="item.key">
        <v-card flat color="transparent">
          <div>
            <v-avatar size="200" color="primary">
              <v-icon color="white" size="100">{{ item.icon }}</v-icon>
            </v-avatar>

            <v-card-title class="d-block text-uppercase text-center">
              {{ $t(`about.${item.key}Title`) }}
            </v-card-title>
            <v-card-text class="d-block body-1 font-weight-light text-center">
              {{ $t(`about.${item.key}Text`) }}
            </v-card-text>
          </div>
        </v-card>
      </v-col>
    </v-row>
  </base-section>
</template>

<script>
import BaseSection from "@/components/BaseSection.vue";

export default {
  name: "About",

  components: {
    BaseSection
  },

  data: () => ({
    list: [
      {
        key: "mission",
        icon: "mdi-bullseye"
      },
      {
        key: "vision",
        icon: "mdi-eye"
      },
      {
        key: "values",
        icon: "mdi-account-heart"
      }
    ]
  })
};
</script>
