export const SERVICES = [
  {
    id: "accounting",
    imgUrl:
      "https://unsplash.com/photos/fJTqyZMOh18/download?force=true&w=2400",
    srcset: [
      "https://unsplash.com/photos/fJTqyZMOh18/download?force=true&w=640 640w",
      "https://unsplash.com/photos/fJTqyZMOh18/download?force=true&w=1920 1920w",
      "https://unsplash.com/photos/fJTqyZMOh18/download?force=true&w=2400 2400w"
    ]
  },
  {
    id: "humanResources",
    imgUrl:
      "https://unsplash.com/photos/n95VMLxqM2I/download?force=true&w=2400",
    srcset: [
      "https://unsplash.com/photos/n95VMLxqM2I/download?force=true&w=640 640w",
      "https://unsplash.com/photos/n95VMLxqM2I/download?force=true&w=1920 1920w",
      "https://unsplash.com/photos/n95VMLxqM2I/download?force=true&w=2400 2400w"
    ]
  },
  {
    id: "consulting",
    imgUrl:
      "https://unsplash.com/photos/46bom4lObsA/download?force=true&w=2400",
    srcset: [
      "https://unsplash.com/photos/46bom4lObsA/download?force=true&w=640 640w",
      "https://unsplash.com/photos/46bom4lObsA/download?force=true&w=1920 1920w",
      "https://unsplash.com/photos/46bom4lObsA/download?force=true&w=2400 2400w"
    ]
  },
  {
    id: "insurance",
    imgUrl:
      "https://unsplash.com/photos/5fNmWej4tAA/download?force=true&w=2400",
    srcset: [
      "https://unsplash.com/photos/5fNmWej4tAA/download?force=true&w=640 640w",
      "https://unsplash.com/photos/5fNmWej4tAA/download?force=true&w=1920 1920w",
      "https://unsplash.com/photos/5fNmWej4tAA/download?force=true&w=2400 2400w"
    ],
    imgs: [
      "https://www.allianz.pt/content/dam/onemarketing/system/AZ_Logo_Twitter_438x220px.png",
      "https://www.seguro-mais-barato.pt/img/parceiros/LogoParceiro_Lusitania.svg",
      "https://www.ordemfarmaceuticos.pt/fotos/noticias/ageas_portugal_logo_principal_180_10040421675e977c24c7ced.png"
    ]
  },
  {
    id: "condo",
    imgUrl:
      "https://unsplash.com/photos/CwTfKH5edSk/download?force=true&w=2400",
    srcset: [
      "https://unsplash.com/photos/CwTfKH5edSk/download?force=true&w=640 640w",
      "https://unsplash.com/photos/CwTfKH5edSk/download?force=true&w=1920 1920w",
      "https://unsplash.com/photos/CwTfKH5edSk/download?force=true&w=2400 2400w"
    ]
  },
  {
    id: "pagaqui",
    imgUrl:
      "https://unsplash.com/photos/em37kS8WJJQ/download?force=true&w=2400",
    srcset: [
      "https://unsplash.com/photos/em37kS8WJJQ/download?force=true&w=640 640w",
      "https://unsplash.com/photos/em37kS8WJJQ/download?force=true&w=1920 1920w",
      "https://unsplash.com/photos/em37kS8WJJQ/download?force=true&w=2400 2400w"
    ]
  }
];
