<template>
  <v-app-bar
    app
    elevate-on-scroll
    color="white"
    :height="!$vuetify.breakpoint.mdAndDown ? 100 : 80"
  >
    <router-link to="/">
      <v-avatar size="70">
        <v-img
          contain
          max-height="70%"
          :src="require('../assets/logo.svg')"
        ></v-img>
      </v-avatar>
    </router-link>

    <router-link to="/" class="text-decoration-none">
      <v-toolbar-title class="text-h4">
        <span class="font-weight-light primary--text">Define</span>
        <span class="font-weight-regular secondary--text">Conta</span>
      </v-toolbar-title>
    </router-link>

    <v-spacer></v-spacer>

    <v-toolbar-items class="hidden-sm-and-down">
      <template v-for="btn in menu">
        <v-btn
          v-if="!btn.submenu"
          :key="btn.name"
          link
          exact
          color="white"
          elevation="0"
          active-class="no-active"
          to="/"
          @click="$vuetify.goTo(`#${btn.name}`)"
        >
          {{ $t(`menu.${btn.name}`) }}
        </v-btn>

        <v-menu offset-y open-on-hover v-else tile :key="btn.name">
          <template v-slot:activator="{ on, attrs, value }">
            <v-btn
              link
              exact
              color="white"
              elevation="0"
              to="/"
              active-class="no-active"
              @click="$vuetify.goTo(`#${btn.name}`)"
              v-bind="attrs"
              v-on="on"
            >
              {{ $t(`menu.${btn.name}`) }}
              <v-icon right>
                {{ value ? "mdi-chevron-up" : "mdi-chevron-down" }}
              </v-icon>
            </v-btn>
          </template>

          <v-list>
            <v-list-item
              v-for="(item, index) in btn.submenu"
              :key="index"
              :to="item.name"
              link
            >
              <v-list-item-title>
                {{ $t(`${btn.name}.${item.name}.title`) }}
              </v-list-item-title>
            </v-list-item>
          </v-list>
        </v-menu>
      </template>
    </v-toolbar-items>

    <v-app-bar-nav-icon
      class="hidden-md-and-up mr-0"
      @click.stop="$emit('update:drawer', !drawer)"
    ></v-app-bar-nav-icon>
  </v-app-bar>
</template>

<script>
export default {
  name: "app-bar",

  props: {
    menu: {
      required: true
    },
    drawer: {
      required: true
    }
  }
};
</script>

<style lang="sass" scoped>
.v-btn--active.no-active
  &:not(:focus):not(:hover)::before
    opacity: 0 !important

  &:focus::before,
  &:hover::before
    opacity: 0.08 !important
</style>
