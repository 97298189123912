<template>
  <v-sheet id="contacts" color="secondary" dark tag="section" tile>
    <div class="py-6 py-md-12"></div>

    <v-container>
      <v-row>
        <v-col cols="12" md="6" class="pr-md-8">
          <div class="mapouter">
            <div class="gmap_canvas">
              <iframe
                width="100%"
                height="500"
                id="gmap_canvas"
                src="https://maps.google.com/maps?q=defineconta&t=&z=15&ie=UTF8&iwloc=&output=embed"
                frameborder="0"
                scrolling="no"
                marginheight="0"
                marginwidth="0"
              ></iframe>
            </div>
          </div>

          <div class="pt-12">
            <v-row>
              <v-col cols="12" xl="6">
                <v-card color="transparent" flat>
                  <div class="d-flex flex-no-wrap">
                    <v-avatar class="ma-3" size="50" color="primary">
                      <v-icon>mdi-home</v-icon>
                    </v-avatar>
                    <div>
                      <v-card-subtitle>Morada</v-card-subtitle>

                      <v-card-text>
                        Rua da Gândara, n.º 893<br />
                        4520-606 São João de Ver<br />
                        Santa Maria da Feira, Portugal
                      </v-card-text>
                    </div>
                  </div>
                </v-card>
              </v-col>

              <v-col cols="12" xl="6">
                <v-card color="transparent" flat>
                  <div class="d-flex flex-no-wrap">
                    <v-avatar class="ma-3" size="50" color="primary">
                      <v-icon>mdi-phone</v-icon>
                    </v-avatar>
                    <div>
                      <v-card-subtitle>Contactos</v-card-subtitle>

                      <v-card-text>
                        <div class="d-flex flex-column">
                          <a href="tel:+351256111320">256 111 320</a>
                          <span class="caption text--secondary">
                            (Preço de uma chamada para a rede fixa.)
                          </span>
                        </div>
                        <div class="pt-1">
                          <a href="mailto:geral@defineconta.com">
                            geral@defineconta.com
                          </a>
                        </div>
                      </v-card-text>
                    </div>
                  </div>
                </v-card>
              </v-col>
            </v-row>
          </div>
        </v-col>

        <v-col cols="12" md="6">
          <div class="py-8 py-md-0"></div>

          <h2 class="display-1 font-weight-bold mb-3">Contacte-nos</h2>

          <v-responsive class="mx-auto mb-12" width="56">
            <v-divider></v-divider>
          </v-responsive>

          <v-theme-provider light>
            <contact-form />
          </v-theme-provider>
        </v-col>
      </v-row>
    </v-container>

    <div class="py-6 py-md-12"></div>
  </v-sheet>
</template>

<script>
import ContactForm from "../components/ContactForm";

export default {
  components: {
    ContactForm
  }
};
</script>

<style>
.mapouter {
  position: relative;
  text-align: right;
  height: 500px;
  width: 100%;
}
.gmap_canvas {
  overflow: hidden;
  background: none !important;
  height: 500px;
  width: 100%;
}
</style>
