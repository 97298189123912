<template>
  <section :id="id" :class="color">
    <div class="py-6 py-md-12"></div>

    <v-container class="text-center">
      <h2 class="display-2 font-weight-bold secondary--text mb-3">
        {{ title }}
      </h2>

      <v-responsive class="mx-auto mb-8" width="56">
        <v-divider></v-divider>
      </v-responsive>

      <v-responsive class="mx-auto body-1 font-weight-light" max-width="1024">
        <slot />
      </v-responsive>

      <div></div>
    </v-container>

    <div class="py-6 py-md-12"></div>
  </section>
</template>

<script>
export default {
  props: {
    id: String,
    color: String,
    title: String
  }
};
</script>
