<template>
  <v-form v-model="valid" @submit.prevent="onSubmit">
    <v-row>
      <v-col cols="12">
        <v-text-field
          v-model="form.name"
          name="name"
          flat
          label="Nome*"
          solo
          :rules="nameRules"
          required
        ></v-text-field>
      </v-col>

      <v-col cols="12">
        <v-text-field
          v-model="form.email"
          name="email"
          flat
          label="Email*"
          solo
          :rules="emailRules"
          type="email"
          required
        ></v-text-field>
      </v-col>

      <v-col cols="12">
        <v-text-field
          v-model="form.subject"
          name="subject"
          flat
          label="Assunto*"
          solo
          :rules="subjectRules"
          required
        ></v-text-field>
      </v-col>

      <v-col cols="12">
        <v-textarea
          v-model="form.message"
          name="message"
          flat
          label="Mensagem*"
          solo
          :rules="messageRules"
          counter="200"
          required
        ></v-textarea>
      </v-col>

      <v-col cols="12">
        <v-checkbox
          v-model="checkbox"
          name="checkbox"
          :rules="checkboxRules"
          required
          class="mt-0"
        >
          <template v-slot:label>
            <div>
              {{ $t("contacts.form.checkbox.label1") }}
              <a @click.stop="dialog = true">
                {{ $t("contacts.form.checkbox.label2") }} </a
              >.
            </div>
          </template>
        </v-checkbox>
      </v-col>

      <v-col cols="auto">
        <div class="d-flex align-center">
          <div>
            <v-btn
              color="primary"
              x-large
              type="submit"
              :loading="loading"
              :disabled="!valid"
            >
              Enviar
            </v-btn>
          </div>
          <div>
            <v-slide-x-transition>
              <div
                v-if="formState !== null"
                class="caption pl-10"
                :class="[!formState ? 'error--text' : null]"
              >
                <template
                  v-if="formState"
                  v-html="$t('contacts.form.submit.success')"
                />
                <template v-else v-html="$t('contacts.form.submit.error')" />
              </div>
            </v-slide-x-transition>
          </div>
        </div>
      </v-col>
    </v-row>

    <contacts-dialog :state.sync="dialog" />
  </v-form>
</template>

<script>
import axios from "axios";
import ContactsDialog from "./ContactsDialog.vue";

export default {
  components: {
    ContactsDialog
  },

  data: () => ({
    valid: false,
    dialog: false,
    loading: false,
    form: {
      name: "",
      email: "",
      subject: "",
      message: ""
    },
    formState: null,
    checkbox: false
  }),

  computed: {
    nameRules() {
      return [
        v => !!v || this.$t("contacts.form.name.validation1"),
        v => (v && v.length <= 20) || this.$t("contacts.form.name.validation2")
      ];
    },
    emailRules() {
      return [
        v => !!v || this.$t("contacts.form.email.validation1"),
        v => /.+@.+\..+/.test(v) || this.$t("contacts.form.email.validation2")
      ];
    },
    subjectRules() {
      return [
        v => !!v || this.$t("contacts.form.subject.validation1"),
        v =>
          (v && v.length <= 50) || this.$t("contacts.form.subject.validation2")
      ];
    },
    messageRules() {
      return [v => !!v || this.$t("contacts.form.message.validation1")];
    },
    checkboxRules() {
      return [v => !!v || this.$t("contacts.form.checkbox.validation1")];
    }
  },

  methods: {
    onSubmit() {
      this.loading = true;
      axios
        .post("mail.php", this.form)
        .then(() => {
          this.form = {
            name: "",
            email: "",
            subject: "",
            message: ""
          };
        })
        .catch(() => {})
        .then(response => {
          this.loading = false;
          this.formState = response;
          setTimeout(() => {
            this.formState = null;
          }, 5000);
        });
    }
  }
};
</script>
