<template>
  <v-sheet tag="section" class="grey lighten-3">
    <div class="py-4"></div>

    <v-container>
      <v-row>
        <v-col cols="12" md="8" class="d-flex align-center">
          <v-row>
            <v-col
              class="font-weight-light d-flex align-center justify-center justify-md-start flex-md-grow-0 text-uppercase"
            >
              <div :style="{ whiteSpace: 'nowrap' }">Siga-nos</div>
            </v-col>
            <v-col
              cols="12"
              md="8"
              class="d-flex flex-grow-1 justify-center justify-md-start"
            >
              <div>
                <v-btn
                  v-for="social in socialList"
                  :key="social.icon"
                  dark
                  fab
                  tile
                  color="primary"
                  class="ma-2"
                  :href="social.link"
                  target="_blank"
                >
                  <v-icon dark>{{ social.icon }}</v-icon>
                </v-btn>
              </div>
            </v-col>
          </v-row>
        </v-col>

        <v-col
          cols="12"
          md="4"
          class="d-flex justify-center justify-md-end align-center mt-6 mt-md-0"
        >
          <a
            class="livro-logo"
            target="_blank"
            href="https://www.livroreclamacoes.pt/inicio/reclamacao"
          >
            <v-img
              width="140"
              src="https://publicitario.pt/application/files/9215/3688/0023/livro_reclamacoes-500x-207.png"
            />
          </a>
        </v-col>
      </v-row>
    </v-container>

    <div class="py-4"></div>
  </v-sheet>
</template>

<script>
export default {
  data: () => ({
    socialList: [
      {
        icon: "mdi-facebook",
        link: "https://www.facebook.com/DefineConta"
      },
      {
        icon: "mdi-instagram",
        link: "https://www.instagram.com/defineconta/"
      },
      {
        icon: "mdi-twitter",
        link: ""
      },
      {
        icon: "mdi-linkedin",
        link: ""
      }
    ]
  })
};
</script>
