<template>
  <div>
    <section id="hero">
      <v-row no-gutters>
        <v-responsive
          :min-height="'calc(100vh - ' + $vuetify.application.top + 'px)'"
          :height="'calc(100vh - ' + $vuetify.application.top + 'px)'"
        >
          <div class="video-container">
            <video muted autoplay loop>
              <source :src="require('@/assets/clip.mp4')" type="video/mp4" />
            </video>
          </div>
          <v-theme-provider light :style="{ position: 'relative' }">
            <v-container fill-height>
              <v-row align="center" class=" mx-auto mt-n16" justify="center">
                <v-col class="text-center" cols="12" tag="h1">
                  <span
                    :class="[
                      $vuetify.breakpoint.smAndDown ? 'display-1' : 'display-2'
                    ]"
                    class="font-weight-light"
                  >
                    Benvindo à
                  </span>

                  <br />

                  <span
                    :class="[
                      $vuetify.breakpoint.smAndDown ? 'display-3' : 'display-4'
                    ]"
                    class="font-weight-black"
                  >
                    <span class="font-weight-light primary--text">Define</span>
                    <span class="font-weight-regular secondary--text">
                      Conta
                    </span>
                  </span>
                </v-col>

                <v-btn
                  class="align-self-end"
                  fab
                  outlined
                  @click="$vuetify.goTo('#about')"
                >
                  <v-icon>mdi-chevron-double-down</v-icon>
                </v-btn>
              </v-row>
            </v-container>
          </v-theme-provider>
        </v-responsive>
      </v-row>
    </section>

    <about />

    <team />

    <services />

    <contacts />

    <social />
  </div>
</template>

<script>
import About from "./About.vue";
import Team from "./Team.vue";
import Services from "./Services";
import Social from "./Social.vue";
import Contacts from "./Contacts.vue";

export default {
  name: "MainPage",

  components: {
    About,
    Team,
    Services,
    Social,
    Contacts
  },

  data() {
    return {};
  }
};
</script>

<style lang="sass" scoped>
.video-container
  position: absolute
  width: 100%
  height: 100%

  video
    height: 100%
    width: 177.77777778vh /* 100 * 16 / 9 */
    min-width: 100%
    min-height: 56.25vw /* 100 * 9 / 16 */
    position: absolute
    left: 50% /* % of surrounding element */
    top: 50%
    transform: translate(-50%, -50%) /* % of current element */
    opacity: 0.5
</style>
