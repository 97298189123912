<template>
  <v-app>
    <app-bar :menu="menu" :drawer.sync="drawer" />

    <app-drawer :menu="menu" :drawer.sync="drawer" />

    <v-main>
      <v-slide-y-transition>
        <router-view />
      </v-slide-y-transition>
    </v-main>

    <app-footer />
  </v-app>
</template>

<script>
import AppBar from "./components/AppBar";
import AppDrawer from "./components/AppDrawer";
import AppFooter from "./components/AppFooter";

export default {
  name: "App",

  components: {
    AppBar,
    AppDrawer,
    AppFooter
  },

  data: () => ({
    drawer: false,
    menu: [
      { name: "about" },
      { name: "team" },
      {
        name: "services",
        submenu: [
          { name: "accounting" },
          { name: "humanResources" },
          { name: "consulting" },
          { name: "insurance" },
          { name: "condo" },
          { name: "pagaqui" }
        ]
      },
      { name: "contacts" }
    ]
  })
};
</script>
