<template>
  <v-hover v-slot="{ hover }">
    <div class="overflow-hidden">
      <v-card
        dark
        :min-height="$vuetify.breakpoint.mdAndDown ? 250 : 500"
        tile
        flat
        :img="service.imgUrl"
        :class="{ 'on-hover': hover && !$vuetify.breakpoint.mdAndDown }"
        @click="$router.push(service.id)"
        class="d-flex align-center justify-center"
      >
        <div class="darken d-flex justify-center align-center">
          <v-fade-transition>
            <v-overlay
              v-if="hover && !$vuetify.breakpoint.mdAndDown"
              absolute
              color="secondary"
            >
              <div class="d-flex justify-center">
                <v-avatar class="pa-8" color="primary" size="200">
                  <div class="fill-height">
                    <inline-svg
                      width="100%"
                      :src="require(`../assets/${service.id}.svg`)"
                      @loaded="svgLoaded"
                    ></inline-svg>
                  </div>
                </v-avatar>
              </div>

              <v-card-title
                class="text-uppercase font-weight-light justify-center"
              >
                {{ $t(`services.${service.id}.title`) }}
              </v-card-title>
            </v-overlay>
          </v-fade-transition>

          <v-fade-transition>
            <v-card-title
              v-if="!hover || $vuetify.breakpoint.mdAndDown"
              class="text-h5 text-md-h4 text-center word-break-normal text-uppercase font-weight-regular"
            >
              {{ $t(`services.${service.id}.title`) }}
            </v-card-title>
          </v-fade-transition>
        </div>
      </v-card>
    </div>
  </v-hover>
</template>

<script>
import Vivus from "vivus";
import InlineSvg from "vue-inline-svg";

export default {
  name: "service-item",

  components: {
    InlineSvg
  },

  props: {
    service: {
      required: true
    }
  },

  data: () => ({
    ctx: null
  }),

  methods: {
    svgLoaded(svg) {
      this.ctx = new Vivus(svg, { duration: 100 });
    }
  }
};
</script>

<style lang="scss" scoped>
.v-card {
  transition: all 0.4s ease-in-out;
}
.v-card.on-hover {
  transform: scale(1.2);
}
.darken {
  width: 100%;
  height: 100%;
  background-color: rgba($color: #000000, $alpha: 0.3);
  position: absolute;
  z-index: 0;
}
.word-break-normal {
  word-break: normal;
}
</style>
