<template>
  <v-dialog
    v-model="dialog"
    scrollable
    max-width="800px"
    transition="dialog-bottom-transition"
  >
    <v-card>
      <v-toolbar flat>
        <v-spacer></v-spacer>
        <v-btn icon @click="dialog = false">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-toolbar>

      <v-tabs v-model="tab" class="px-4">
        <v-tab>
          Termos e Condições
        </v-tab>

        <v-tab>
          Política de Privacidade
        </v-tab>
      </v-tabs>

      <v-tabs-items v-model="tab" class="overflow-y-auto">
        <v-tab-item>
          <v-card flat>
            <v-card-text>
              <p>
                <a href="http://defineconta.com">DefineConta</a>, concorda em
                cumprir estes termos de serviço, todas as leis e regulamentos
                aplicáveis e concorda que é responsável pelo cumprimento de
                todas as leis locais aplicáveis. Se você não concordar com algum
                desses termos, está proibido de usar ou acessar este site. Os
                materiais contidos neste site são protegidos pelas leis de
                direitos autorais e marcas comerciais aplicáveis.
              </p>

              <h3>Uso de Licença</h3>
              <p class="mb-0">
                É concedida permissão para baixar temporariamente uma cópia dos
                materiais (informações ou software) no site DefineConta, apenas
                para visualização transitória pessoal e não comercial. Esta é a
                concessão de uma licença, não uma transferência de título e, sob
                esta licença, você não pode:
              </p>
              <ol>
                <li>modificar ou copiar os materiais;</li>
                <li>
                  usar os materiais para qualquer finalidade comercial ou para
                  exibição pública (comercial ou não comercial);
                </li>
                <li>
                  tentar descompilar ou fazer engenharia reversa de qualquer
                  software contido no site DefineConta;
                </li>
                <li>
                  remover quaisquer direitos autorais ou outras notações de
                  propriedade dos materiais;
                </li>
                <li>
                  transferir os materiais para outra pessoa ou 'espelhe' os
                  materiais em qualquer outro servidor.
                </li>
              </ol>
              <p />

              <p>
                Esta licença será automaticamente rescindida se você violar
                alguma dessas restrições e poderá ser rescindida por DefineConta
                a qualquer momento. Ao encerrar a visualização desses materiais
                ou após o término desta licença, você deve apagar todos os
                materiais baixados em sua posse, seja em formato eletrónico ou
                impresso.
              </p>

              <h3>Isenção de responsabilidade</h3>
              <ol>
                <li>
                  Os materiais no site da DefineConta são fornecidos 'como
                  estão'. A DefineConta não oferece garantias, expressas ou
                  implícitas, e, por este meio, isenta e nega todas as outras
                  garantias, incluindo, sem limitação, garantias implícitas ou
                  condições de comercialização, adequação a um fim específico ou
                  não violação de propriedade intelectual ou outra violação de
                  direitos.
                </li>
                <li>
                  Além disso, o DefineConta não garante ou faz qualquer
                  representação relativa à precisão, aos resultados prováveis ou
                  à confiabilidade do uso dos materiais no seu site ou de outra
                  forma relacionado a esses materiais ou em sites vinculados a
                  este site.
                </li>
              </ol>
              <p></p>

              <h3>Limitações</h3>
              <p>
                Em nenhum caso o DefineConta ou seus fornecedores serão
                responsáveis por quaisquer danos (incluindo, sem limitação,
                danos por perda de dados ou lucro ou devido a interrupção dos
                negócios) decorrentes do uso ou da incapacidade de usar os
                materiais em DefineConta mesmo que DefineConta ou um
                representante autorizado da DefineConta tenha sido notificado
                oralmente ou por escrito da possibilidade de tais danos. Como
                algumas jurisdições não permitem limitações em garantias
                implícitas, ou limitações de responsabilidade por danos
                consequentes ou incidentais, essas limitações podem não se
                aplicar a si.
              </p>

              <h3>Precisão dos materiais</h3>
              <p>
                Os materiais exibidos no site da DefineConta podem incluir erros
                técnicos, tipográficos ou fotográficos. A DefineConta não
                garante que qualquer material no site seja preciso, completo ou
                atual. A DefineConta pode fazer alterações nos materiais
                contidos no site a qualquer momento, sem aviso prévio. No
                entanto, a DefineConta não se compromete a atualizar os
                materiais.
              </p>

              <h3>Links</h3>
              <p>
                A DefineConta não analisou todos os sites vinculados ao seu site
                e não é responsável pelo conteúdo de nenhum site vinculado. A
                inclusão de qualquer link não implica endosso por DefineConta do
                site. O uso de qualquer site vinculado é por conta e risco do
                utilizador.
              </p>

              <h4>1. Modificações</h4>
              <p>
                A DefineConta pode revisar estes termos de serviço do site a
                qualquer momento, sem aviso prévio. Ao usar este site, você
                concorda em ficar vinculado à versão atual desses termos de
                serviço.
              </p>

              <h4>2. Lei aplicável</h4>
              <p>
                Estes termos e condições são regidos e interpretados de acordo
                com as leis da DefineConta e você se submete irrevogavelmente à
                jurisdição exclusiva dos tribunais naquela localidade.
              </p>
            </v-card-text>
          </v-card>
        </v-tab-item>

        <v-tab-item>
          <v-card flat>
            <v-card-text>
              <p>
                A sua privacidade é importante para nós. É política do
                DefineConta, Prestação de Serviços, Lda respeitar a sua
                privacidade em relação a qualquer informação sua que possamos
                coletar no site
                <a href="http://defineconta.com">DefineConta</a>, e outros sites
                que possuímos e operamos.
              </p>
              <p>
                Solicitamos informações pessoais apenas quando realmente
                precisamos delas para lhe fornecer um serviço. Fazemo-lo por
                meios justos e legais, com o seu conhecimento e consentimento.
                Também o informamos da razão para tal e como serão usados.
              </p>
              <p>
                Apenas retemos as informações coletadas pelo tempo necessário
                para fornecer o serviço solicitado. Quando armazenamos dados,
                protegemos dentro de meios comercialmente aceitáveis para evitar
                perdas e roubos, bem como acesso, divulgaçãoo, cópia, uso ou
                modificação não autorizados.
              </p>
              <p>
                Não compartilhamos informações de identificação pessoal
                publicamente ou com terceiros, exceto quando exigido por lei.
              </p>
              <p>
                O nosso site pode ter links para sites externos que não são
                operados por nós. Esteja ciente de que não temos controlo sobre
                o conteúdo e práticas desses sites e não podemos aceitar
                responsabilidade por suas respectivas políticas de privacidade.
              </p>
              <p>
                Você é livre para recusar a nossa solicitação de informações
                pessoais, entendendo que talvez não possamos fornecer alguns dos
                serviços desejados.
              </p>
              <p>
                O uso continuado de nosso site será considerado como aceitação
                das nossas práticas em torno de privacidade e informações
                pessoais. Se você tiver alguma dúvida sobre como lidamos com
                dados do utilizador e informações pessoais, entre em contacto
                connosco.
              </p>

              <h3>Compromisso do utilizador</h3>
              <p>
                O utilizador se compromete a fazer uso adequado dos conteúdos e
                da informação que o DefineConta, Prestação de Serviços, Lda
                oferece no site e com caráter enunciativo, mas não limitativo:
              </p>
              <ul>
                <li>
                  A) Não se envolver em atividades que sejam ilegais ou
                  contrárias à boa fé a à ordem pública;
                </li>
                <li>
                  B) Não difundir propaganda ou conteúdo de natureza racista,
                  xenófoba, homofóbica, ou sobre casinos, casas de apostas
                  online, jogos de sorte e azar, qualquer tipo de pornografia
                  ilegal, de apologia ao terrorismo ou contra os direitos
                  humanos;
                </li>
                <li>
                  C) Não causar danos aos sistemas físicos (hardwares) e lógicos
                  (softwares) do DefineConta, Prestação de Serviços, Lda, de
                  seus fornecedores ou terceiros, para introduzir ou disseminar
                  vírus informáticos ou quaisquer outros sistemas de hardware ou
                  software que sejam capazes de causar danos anteriormente
                  mencionados.
                </li>
              </ul>
              <p />

              <h3>Mais informações</h3>
              <p>
                Esperemos que esteja esclarecido e, como mencionado
                anteriormente, se houver algo que você não tem certeza se
                precisa ou não, geralmente é mais seguro deixar os cookies
                ativados, caso interaja com um dos recursos que você usa no
                nosso site.
              </p>
              <p>
                Esta política é efetiva a partir de
                <strong>Fevereiro de 2021</strong>.
              </p>
            </v-card-text>
          </v-card>
        </v-tab-item>
      </v-tabs-items>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  props: {
    state: {
      default: false,
      required: true
    }
  },

  data: () => ({
    dialog: false,
    tab: null
  }),

  watch: {
    state(val) {
      this.dialog = val;
    },
    dialog(val) {
      this.$emit("update:state", val);
    }
  }
};
</script>
